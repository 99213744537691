var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"d-flex flex-row flex-grow-1"},[_c('PageTitle',{staticClass:"ft font-size-md",attrs:{"text":"Stock Management (Fuels)"}}),_c('div',[_c('v-text-field',{staticClass:"mt-2 ft font-size-sm black--text",staticStyle:{"min-width":"400px"},attrs:{"dense":"","append-icon":"search","light":"","single-line":"","solo-inverted":"","placeholder":"Search by branch or product name ...","hide-details":"","flat":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('div',{staticClass:"d-flex flex-row flex-grow-1",staticStyle:{"align-items":"center"},attrs:{"id":"result"}},[_c('v-chip',{staticClass:"ml-2 mt-3 white--text font-weight-bold  ft ",attrs:{"label":"","color":"success"}},[_vm._v(" List Of Available Stocks For "),_c('span',{staticClass:"text-uppercase  ml-2"},[_vm._v(_vm._s(_vm._f("timestamp")(_vm.date,"MMMM, YYYY")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-2 ml-3",attrs:{"fab":"","icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.$store.dispatch('stocks/products', {
              page: _vm.pagination.page,
              limit: _vm.pagination.limit
            })}}},on),[_c('i',{staticClass:"material-icons-outlined font-size-md primary--text font-weight-bold"},[_vm._v("refresh")])])]}}])},[_c('span',{staticClass:" ft font-weight-bold "},[_vm._v("Refresh ")])])],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-chip-group',{attrs:{"light":"","multiple":"","show-arrows":""},model:{value:(_vm.tag),callback:function ($$v) {_vm.tag=$$v},expression:"tag"}},[(_vm.isBranchesLoading)?_c('SkeletonPreloader',{staticClass:"ml-2",attrs:{"count":12}}):_vm._l((_vm.tags),function(tag,index){return _c('v-chip',{key:index,staticClass:"box-shadow-light ft",attrs:{"label":"","active-class":"primary--text","color":"white","value":tag.id}},[_c('span',{staticClass:"font-weight-bold text-uppercase"},[_vm._v(_vm._s(tag.name))])])})],2)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"ft  box-shadow-light",attrs:{"headers":_vm.headers,"items":_vm.filteredStocks,"server-items-length":_vm.pagination.total,"loading-text":"Loading... Please wait","loading":_vm.loading,"options":_vm.options,"footer-props":{
          showFirstLastPages: true,
          itemsPerPageOptions: [10, 20, 30]
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.branchId",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"ft font-weight-medium "},[_vm._v(" "+_vm._s(item.branchId.name)+" ")])]}},{key:"item.productId",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{staticClass:" ft font-weight-medium ",attrs:{"small":"","label":"","color":"primary"}},[_c('span',{staticClass:"ft font-weight-medium"},[_vm._v(" "+_vm._s(item.productId.name)+" ")])])]}},{key:"item.minimumThreshold",fn:function(ref){
        var item = ref.item;
return [(item.quantity >= item.productId.minimumThreshold)?_c('v-chip',{staticClass:"font font-weight-bold",attrs:{"label":"","small":"","color":"success"}},[_vm._v(" "+_vm._s(item.productId.minimumThreshold)+" ")]):_c('v-chip',{staticClass:"font font-weight-bold white--text",attrs:{"label":"","small":"","color":"red"}},[_vm._v(" "+_vm._s(item.productId.minimumThreshold)+" ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }