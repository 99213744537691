<template>
  <v-container>
    <div class="d-flex flex-row flex-grow-1">
      <PageTitle class="ft font-size-md" text="Stock Management (Fuels)" />
      <div>
        <v-text-field
          dense
          append-icon="search"
          light
          single-line
          v-model="search"
          style="min-width: 400px;"
          solo-inverted
          placeholder="Search by branch or product name ..."
          class="mt-2 ft font-size-sm black--text"
          hide-details
          flat
        />
      </div>
    </div>
    <div
      class="d-flex flex-row flex-grow-1"
      style="align-items:center;"
      id="result"
    >
      <v-chip
        label
        color="success"
        class="ml-2 mt-3 white--text font-weight-bold  ft "
      >
        List Of Available Stocks For
        <span class="text-uppercase  ml-2">{{
          date | timestamp("MMMM, YYYY")
        }}</span>
      </v-chip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            @click.stop="
              $store.dispatch('stocks/products', {
                page: pagination.page,
                limit: pagination.limit
              })
            "
            fab
            icon
            small
            v-on="on"
            class="mt-2 ml-3"
          >
            <i
              class="material-icons-outlined font-size-md primary--text font-weight-bold"
              >refresh</i
            >
          </v-btn>
        </template>
        <span class=" ft font-weight-bold ">Refresh </span>
      </v-tooltip>
    </div>
    <v-row align="center">
      <v-col cols="12" sm="12">
        <v-chip-group light v-model="tag" multiple show-arrows>
          <SkeletonPreloader
            v-if="isBranchesLoading"
            :count="12"
            class="ml-2"
          />
          <v-chip
            v-else
            label
            active-class="primary--text"
            color="white"
            class="box-shadow-light ft"
            v-for="(tag, index) in tags"
            :value="tag.id"
            :key="index"
          >
            <span class="font-weight-bold text-uppercase">{{ tag.name }}</span>
          </v-chip>
        </v-chip-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="filteredStocks"
          :server-items-length="pagination.total"
          loading-text="Loading... Please wait"
          :loading="loading"
          :options.sync="options"
          class="ft  box-shadow-light"
          :footer-props="{
            showFirstLastPages: true,
            itemsPerPageOptions: [10, 20, 30]
          }"
        >
          <template #item.branchId="{item}">
            <span class="ft font-weight-medium ">
              {{ item.branchId.name }}
            </span>
          </template>
          <template #item.productId="{item}">
            <v-chip small label color="primary" class=" ft font-weight-medium ">
              <span class="ft font-weight-medium">
                {{ item.productId.name }}
              </span>
            </v-chip>
          </template>
          <template #item.minimumThreshold="{item}">
            <v-chip
              v-if="item.quantity >= item.productId.minimumThreshold"
              label
              small
              color="success"
              class="font font-weight-bold"
            >
              {{ item.productId.minimumThreshold }}
            </v-chip>
            <v-chip
              v-else
              label
              small
              color="red"
              class="font font-weight-bold white--text"
            >
              {{ item.productId.minimumThreshold }}
            </v-chip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import { timestamp } from "../../../filters/timestamp";

export default {
  name: "ListOfStocksPerBranchesForProducts",
  components: {
    PageTitle: () => import(`../../../components/PageTitle`),
    SkeletonPreloader: () => import(`../../../components/SkeletonPreloader`)
  },
  data() {
    return {
      date: new Date(),
      tags: [],
      tag: [],
      search: "",
      isBranchesLoading: false,
      headers: [
        { text: "Branch", value: "branchId" },
        { text: "Product", value: "productId" },
        { text: "Qty (Litres)", value: "quantity" },
        { text: "Min. Threshold", value: "minimumThreshold", align: "center" }
      ],
      options: {}
    };
  },
  computed: {
    ...mapGetters({
      loading: "getIsListLoading",
      branches: "orders/getAllBranches",
      pagination: "stocks/getPagination",
      stocks: "stocks/getAllProductStock"
    }),
    filteredStocks() {
      return this.stocks.filter(stock => {
        return this.tag.length > 0
          ? this.tag.includes(stock.branchId.id)
          : stock.branchId.name
              ?.toLowerCase()
              .indexOf(this.search?.toLowerCase()) !== -1 ||
              stock.productId?.name
                ?.toLowerCase()
                .indexOf(this.search?.toLowerCase()) !== -1;
      });
    }
  },

  watch: {
    branches(payload) {
      this.tags = payload.map(branch => {
        return {
          name: branch.name,
          id: branch.id,
          slug: branch.slug
        };
      });
    },
    options: {
      handler(options) {
        const { itemsPerPage, page } = options;
        this.$store.dispatch("stocks/products", { page, limit: itemsPerPage });
      },
      deep: true
    }
  },
  filters: {
    timestamp
  },

  created() {
    this.$store.dispatch("orders/branchList");
    this.$store.dispatch("stocks/products", { page: 1, limit: 10 });
  }
};
</script>
